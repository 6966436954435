.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .6);
    background-image: url("loader.svg");
    background-repeat: no-repeat;
    background-position: center;
}
