.container {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
}

.vkLogo {
    height: 36px;
    width: 36px;
    background-image: url("vk-logo.png");
    background-repeat: no-repeat;
    background-size: cover;
}
