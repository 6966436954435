.button {
    display: inline-block;
    border: 1px solid #d45252;
    border-radius: 3px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    background: #f16161;
    color: #fff;
}

.button:hover {
    background: #d45252;
}
