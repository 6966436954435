.container {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #ddd;
    padding: 0 10px;
    height: 60px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
}

.flex {
    display: flex;
    align-items: center;
}

.logo {
    display: block;
    width: 45px;
    height: 39px;
    background-image: url("pisakaNew5.jpg");
    background-repeat: no-repeat;
    background-size: contain;
}

.meta {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 13px;
    color: #f16161;
}

.link {
    text-decoration: none;
}

.metaInfo {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 13px;
    color: #2587f4;
    margin-left: 8px;
}

@media (max-width: 768px) {
    .metaInfo {
        display: none;
    }
}
