.container {
    width: 421px;
    margin-left: 20px;
    font-family: 'Roboto', sans-serif;
    background: #fff;
}

.wrapper {
    position: sticky;
    top: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 97px);
    padding: 10px 15px 0;
    border-radius: 3px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
}

.visibleButton {
    position: absolute;
    right: 10px;
    top: -30px;
    display: none;
    padding: 8px 15px;
    border: 1px solid #f16161;
    background: #f16161;
    border-radius: 42px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
    color: #fff;
    z-index: 1;
}

@media (max-width: 768px) {
    .container {
        margin-left: 0;
        position: fixed;
        bottom: 0;
        top: auto;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 10;
        padding-bottom: 50px;
    }

    .wrapper {
        overflow-y: auto;
        height: calc(100vh - 220px);
    }

    .visibleButton {
        display: block;
    }

    .isClose .wrapper {
        overflow: hidden;
        height: 180px;
    }
}

.sectionContainer {
    margin-bottom: 30px;
}

.isSticky {
    background: #fff;
    position: sticky;
    bottom: 0;
    margin: -13px -15px;
    padding: 15px;
    border-top: 1px solid #ddd;
    box-shadow: 1px -5px 5px rgba(0, 0, 0, .05);
    z-index: 10;
}

.subSectionContainer {
    margin-bottom: 15px;
}

.sectionContainer:last-child {
    margin-bottom: 0;
}

.container h4 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
}

.container p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 300;
}
