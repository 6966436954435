.input {
    display: block;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 5px 8px;
    font-size: 16px;
    resize: vertical;
    height: 42px;
    outline: none;
}
